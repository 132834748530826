define("discourse/plugins/chat/discourse/services/chat-thread-pane-subscriptions-manager", ["exports", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/services/chat-pane-base-subscriptions-manager"], function (_exports, _chatMessage, _chatPaneBaseSubscriptionsManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadPaneSubscriptionsManager extends _chatPaneBaseSubscriptionsManager.default {
    beforeSubscribe(model) {
      this.messageBusChannel = `/chat/${model.channel.id}/thread/${model.id}`;
      this.messageBusLastId = model.threadMessageBusLastId;
    }
    afterMessage(model, _, __, lastMessageBusId) {
      model.threadMessageBusLastId = lastMessageBusId;
    }
    handleSentMessage(data) {
      if (data.chat_message.user.id === this.currentUser.id && data.staged_id) {
        const stagedMessage = this.handleStagedMessageInternal(this.model.channel, data);
        if (stagedMessage) {
          return;
        }
      }
      const message = _chatMessage.default.create(this.model.channel, data.chat_message);
      message.thread = this.model;
      message.manager = this.messagesManager;
      this.messagesManager.addMessages([message]);
    }

    // NOTE: noop, there is nothing to do when a thread original message
    // is updated inside the thread panel (for now).
    handleThreadOriginalMessageUpdate() {
      return;
    }

    // NOTE: We don't yet handle notices inside of threads so do nothing.
    handleNotice() {
      return;
    }
    _afterDeleteMessage(targetMsg, data) {
      if (this.model.currentUserMembership?.lastReadMessageId === targetMsg.id) {
        this.model.currentUserMembership.lastReadMessageId = data.latest_not_deleted_message_id;
      }
    }
  }
  _exports.default = ChatThreadPaneSubscriptionsManager;
});