define("discourse/plugins/chat/discourse/components/reviewable-chat-message", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _chatChannel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flagged-post-header">
    <LinkTo
      @route="chat.channel.near-message"
      @models={{array
        this.chatChannel.slugifiedTitle
        this.chatChannel.id
        @reviewable.target_id
      }}
    >
      <ChatChannelTitle @channel={{this.chatChannel}} />
    </LinkTo>
  </div>
  
  <div class="post-contents-wrapper">
    <ReviewableCreatedBy @user={{@reviewable.target_created_by}} @tagName="" />
    <div class="post-contents">
      <ReviewablePostHeader
        @reviewable={{@reviewable}}
        @createdBy={{@reviewable.target_created_by}}
        @tagName=""
      />
  
      <div class="post-body">
        {{html-safe (or @reviewable.payload.message_cooked @reviewable.cooked)}}
      </div>
  
      {{#if @reviewable.payload.transcript_topic_id}}
        <div class="transcript">
          <LinkTo
            @route="topic"
            @models={{array "-" @reviewable.payload.transcript_topic_id}}
            class="btn btn-small"
          >
            {{i18n "review.transcript.view"}}
          </LinkTo>
        </div>
      {{/if}}
  
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "NmMCpC+0",
    "block": "[[[10,0],[14,0,\"flagged-post-header\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\",\"@models\"],[\"chat.channel.near-message\",[28,[37,1],[[30,0,[\"chatChannel\",\"slugifiedTitle\"]],[30,0,[\"chatChannel\",\"id\"]],[30,1,[\"target_id\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@channel\"],[[30,0,[\"chatChannel\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n  \"],[8,[39,3],null,[[\"@user\",\"@tagName\"],[[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@reviewable\",\"@createdBy\",\"@tagName\"],[[30,1],[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[[28,[37,6],[[30,1,[\"payload\",\"message_cooked\"]],[30,1,[\"cooked\"]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"payload\",\"transcript_topic_id\"]],[[[1,\"      \"],[10,0],[14,0,\"transcript\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"btn btn-small\"]],[[\"@route\",\"@models\"],[\"topic\",[28,[37,1],[\"-\",[30,1,[\"payload\",\"transcript_topic_id\"]]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,8],[\"review.transcript.view\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@reviewable\",\"&default\"],false,[\"link-to\",\"array\",\"chat-channel-title\",\"reviewable-created-by\",\"reviewable-post-header\",\"html-safe\",\"or\",\"if\",\"i18n\",\"yield\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/reviewable-chat-message.hbs",
    "isStrictMode": false
  });
  let ReviewableChatMessage = _exports.default = (_class = class ReviewableChatMessage extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor2, this);
    }
    get chatChannel() {
      return _chatChannel.default.create(this.args.reviewable.chat_channel);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chatChannel", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "chatChannel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReviewableChatMessage);
});