define("discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mention-without-membership-notice">
    {{#if this.invitationsSent}}
      <span
        class="mention-without-membership-notice__invitation-sent"
        {{chat/later-fn @clearNotice 3000}}
      >
        {{d-icon "check"}}
        <span>
          {{i18n
            "chat.mention_warning.invitations_sent"
            count=this.userIds.length
          }}
        </span>
      </span>
    {{else}}
      <p class="mention-without-membership-notice__body -without-membership">
        <span
          class="mention-without-membership-notice__body__text"
        >{{@notice.data.text}}</span>
        <a
          class="mention-without-membership-notice__body__link"
          href
          {{on "click" this.sendInvitations}}
        >
          {{i18n "chat.mention_warning.invite"}}
        </a>
      </p>
    {{/if}}
  </div>
  */
  {
    "id": "pRXG5pS2",
    "block": "[[[10,0],[14,0,\"mention-without-membership-notice\"],[12],[1,\"\\n\"],[41,[30,0,[\"invitationsSent\"]],[[[1,\"    \"],[11,1],[24,0,\"mention-without-membership-notice__invitation-sent\"],[4,[38,1],[[30,1],3000],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"check\"],null]],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"chat.mention_warning.invitations_sent\"],[[\"count\"],[[30,0,[\"userIds\",\"length\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[14,0,\"mention-without-membership-notice__body -without-membership\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"mention-without-membership-notice__body__text\"],[12],[1,[30,2,[\"data\",\"text\"]]],[13],[1,\"\\n      \"],[11,3],[24,0,\"mention-without-membership-notice__body__link\"],[24,6,\"\"],[4,[38,4],[\"click\",[30,0,[\"sendInvitations\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"chat.mention_warning.invite\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@clearNotice\",\"@notice\"],false,[\"if\",\"chat/later-fn\",\"d-icon\",\"i18n\",\"on\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership.hbs",
    "isStrictMode": false
  });
  let MentionWithoutMembership = _exports.default = (_dec = (0, _service.inject)("chat-api"), (_class = class MentionWithoutMembership extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "invitationsSent", _descriptor2, this);
    }
    get userIds() {
      return this.args.notice.data.user_ids;
    }
    async sendInvitations(event) {
      // preventDefault to avoid a refresh
      event.preventDefault();
      try {
        await this.chatApi.invite(this.args.channel.id, this.userIds, {
          messageId: this.args.notice.data.messageId
        });
        this.invitationsSent = true;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invitationsSent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendInvitations", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendInvitations"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MentionWithoutMembership);
});