define("discourse/plugins/chat/discourse/components/chat-channel-metadata", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-channel-metadata">
    {{#if @channel.lastMessage}}
      <div class="chat-channel-metadata__date">
        {{this.lastMessageFormattedDate}}
      </div>
    {{/if}}
  
    {{#if this.unreadIndicator}}
      <ChatChannelUnreadIndicator @channel={{@channel}} />
    {{/if}}
  </div>
  */
  {
    "id": "dcIpnToE",
    "block": "[[[10,0],[14,0,\"chat-channel-metadata\"],[12],[1,\"\\n\"],[41,[30,1,[\"lastMessage\"]],[[[1,\"    \"],[10,0],[14,0,\"chat-channel-metadata__date\"],[12],[1,\"\\n      \"],[1,[30,0,[\"lastMessageFormattedDate\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"unreadIndicator\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@channel\"],false,[\"if\",\"chat-channel-unread-indicator\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-channel-metadata.hbs",
    "isStrictMode": false
  });
  class ChatChannelMetadata extends _component2.default {
    get unreadIndicator() {
      return this.args.unreadIndicator ?? false;
    }
    get lastMessageFormattedDate() {
      return moment(this.args.channel.lastMessage.createdAt).calendar(null, {
        sameDay: "LT",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "dddd",
        sameElse: "l"
      });
    }
  }
  _exports.default = ChatChannelMetadata;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatChannelMetadata);
});