define("discourse/plugins/chat/discourse/components/chat-drawer/threads", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatDrawer::Header @toggleExpand={{@drawerActions.toggleExpand}}>
    {{#if (and this.chatStateManager.isDrawerExpanded this.chat.activeChannel)}}
      <div class="chat-drawer-header__left-actions">
        <div class="chat-drawer-header__top-line">
          <ChatDrawer::Header::BackLink
            @route="chat.channel"
            @title={{i18n "chat.return_to_list"}}
            @routeModels={{this.chat.activeChannel.routeModels}}
          />
        </div>
      </div>
    {{/if}}
  
    <ChatDrawer::Header::Title @title="chat.threads.list" />
  
    <ChatDrawer::Header::RightActions @drawerActions={{@drawerActions}} />
  </ChatDrawer::Header>
  
  {{#if this.chatStateManager.isDrawerExpanded}}
    <div class="chat-drawer-content" {{did-insert this.fetchChannel}}>
      {{#if this.chat.activeChannel}}
        <ChatThreadList
          @channel={{this.chat.activeChannel}}
          @includeHeader={{false}}
        />
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "hyK1qjXq",
    "block": "[[[8,[39,0],null,[[\"@toggleExpand\"],[[30,1,[\"toggleExpand\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[30,0,[\"chat\",\"activeChannel\"]]],null],[[[1,\"    \"],[10,0],[14,0,\"chat-drawer-header__left-actions\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-drawer-header__top-line\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@route\",\"@title\",\"@routeModels\"],[\"chat.channel\",[28,[37,4],[\"chat.return_to_list\"],null],[30,0,[\"chat\",\"activeChannel\",\"routeModels\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@title\"],[\"chat.threads.list\"]],null],[1,\"\\n\\n  \"],[8,[39,6],null,[[\"@drawerActions\"],[[30,1]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"  \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[38,7],[[30,0,[\"fetchChannel\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[1,\"      \"],[8,[39,8],null,[[\"@channel\",\"@includeHeader\"],[[30,0,[\"chat\",\"activeChannel\"]],false]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@drawerActions\"],false,[\"chat-drawer/header\",\"if\",\"and\",\"chat-drawer/header/back-link\",\"i18n\",\"chat-drawer/header/title\",\"chat-drawer/header/right-actions\",\"did-insert\",\"chat-thread-list\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-drawer/threads.hbs",
    "isStrictMode": false
  });
  let ChatDrawerThreads = _exports.default = (_class = class ChatDrawerThreads extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor4, this);
    }
    fetchChannel() {
      if (!this.args.params?.channelId) {
        return;
      }
      return this.chatChannelsManager.find(this.args.params.channelId).then(channel => {
        this.chat.activeChannel = channel;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchChannel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatDrawerThreads);
});