define("discourse/plugins/chat/discourse/components/chat/message/error", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @message.error}}
    <div class="chat-message-error">
      {{#if (eq @message.error "network_error")}}
        <DButton
          class="chat-message-error__retry-btn"
          @action={{fn @onRetry @message}}
          @icon="exclamation-circle"
        >
          <span class="chat-message-error__retry-btn-title">
            {{i18n "chat.retry_staged_message.title"}}
          </span>
          <span class="chat-message-error__retry-btn-action">
            {{i18n "chat.retry_staged_message.action"}}
          </span>
        </DButton>
      {{else}}
        {{@message.error}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "Ut7WvhJu",
    "block": "[[[41,[30,1,[\"error\"]],[[[1,\"  \"],[10,0],[14,0,\"chat-message-error\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1,[\"error\"]],\"network_error\"],null],[[[1,\"      \"],[8,[39,2],[[24,0,\"chat-message-error__retry-btn\"]],[[\"@action\",\"@icon\"],[[28,[37,3],[[30,2],[30,1]],null],\"exclamation-circle\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"chat-message-error__retry-btn-title\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"chat.retry_staged_message.title\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"chat-message-error__retry-btn-action\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"chat.retry_staged_message.action\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,1,[\"error\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@message\",\"@onRetry\"],false,[\"if\",\"eq\",\"d-button\",\"fn\",\"i18n\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/message/error.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});