define("discourse/plugins/chat/discourse/connectors/user-preferences-nav/chat-preferences", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatPreferences extends _component.default {
    static shouldRender(_ref, _ref2) {
      let {
        model
      } = _ref;
      let {
        siteSettings,
        currentUser
      } = _ref2;
      return siteSettings.chat_enabled && (model.can_chat || currentUser?.admin);
    }
  }
  _exports.default = ChatPreferences;
});