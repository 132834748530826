define("discourse/plugins/chat/discourse/components/chat/modal/move-message-to-channel", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/lib/ajax-error", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _template, _utils, _ajaxError, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="chat-modal-move-message-to-channel"
    @inline={{@inline}}
    @title={{i18n "chat.move_to_channel.title"}}
  >
    <:body>
      {{#if this.selectedMessageCount}}
        <p>{{this.instructionsText}}</p>
      {{/if}}
  
      <ChatChannelChooser
        @class="chat-modal-move-message-to-channel__channel-chooser"
        @content={{this.availableChannels}}
        @value={{this.destinationChannelId}}
        @nameProperty="title"
      />
    </:body>
    <:footer>
      <DButton
        @icon="sign-out-alt"
        @disabled={{this.disableMoveButton}}
        @action={{this.moveMessages}}
        @label="chat.move_to_channel.confirm_move"
        class="btn-primary"
      />
      <DButton @label="cancel" @action={{@closeModal}} class="btn-flat" />
    </:footer>
  </DModal>
  */
  {
    "id": "CzuLeqnZ",
    "block": "[[[8,[39,0],[[24,0,\"chat-modal-move-message-to-channel\"]],[[\"@closeModal\",\"@inline\",\"@title\"],[[30,1],[30,2],[28,[37,1],[\"chat.move_to_channel.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"selectedMessageCount\"]],[[[1,\"      \"],[10,2],[12],[1,[30,0,[\"instructionsText\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@class\",\"@content\",\"@value\",\"@nameProperty\"],[\"chat-modal-move-message-to-channel__channel-chooser\",[30,0,[\"availableChannels\"]],[30,0,[\"destinationChannelId\"]],\"title\"]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@icon\",\"@disabled\",\"@action\",\"@label\"],[\"sign-out-alt\",[30,0,[\"disableMoveButton\"]],[30,0,[\"moveMessages\"]],\"chat.move_to_channel.confirm_move\"]],null],[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@inline\"],false,[\"d-modal\",\"i18n\",\"if\",\"chat-channel-chooser\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/modal/move-message-to-channel.hbs",
    "isStrictMode": false
  });
  let ChatModalMoveMessageToChannel = _exports.default = (_class = class ChatModalMoveMessageToChannel extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor4, this);
      _initializerDefineProperty(this, "destinationChannelId", _descriptor5, this);
    }
    get sourceChannel() {
      return this.args.model.sourceChannel;
    }
    get selectedMessageIds() {
      return this.args.model.selectedMessageIds;
    }
    get selectedMessageCount() {
      return this.selectedMessageIds?.length;
    }
    get disableMoveButton() {
      return (0, _utils.isBlank)(this.destinationChannelId);
    }
    get availableChannels() {
      return (this.args.model.availableChannels || this.chatChannelsManager.publicMessageChannels).rejectBy("id", this.sourceChannel.id);
    }
    get instructionsText() {
      return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.move_to_channel.instructions", {
        channelTitle: this.sourceChannel.escapedTitle,
        count: this.selectedMessageCount
      }));
    }
    moveMessages() {
      return this.chatApi.moveChannelMessages(this.sourceChannel.id, {
        message_ids: this.selectedMessageIds,
        destination_channel_id: this.destinationChannelId
      }).then(response => {
        this.args.closeModal();
        this.router.transitionTo("chat.channel.near-message", "-", response.destination_channel_id, response.first_moved_message_id);
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "destinationChannelId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "moveMessages", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "moveMessages"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatModalMoveMessageToChannel);
});