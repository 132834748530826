define("discourse/plugins/chat/discourse/components/chat/modal/toggle-channel-status", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _template, _ajaxError, _discourseI18n, _chatChannel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="chat-modal-toggle-channel-status"
    @inline={{@inline}}
    @title={{i18n this.modalTitle}}
  >
    <:body>
      <p
        class="chat-modal-toggle-channel-status__instructions"
      >{{this.instructions}}</p>
    </:body>
    <:footer>
      <DButton
        @action={{this.onStatusChange}}
        @label={{this.buttonLabel}}
        id="chat-channel-toggle-btn"
        class="btn-primary"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "yZuFKWSS",
    "block": "[[[8,[39,0],[[24,0,\"chat-modal-toggle-channel-status\"]],[[\"@closeModal\",\"@inline\",\"@title\"],[[30,1],[30,2],[28,[37,1],[[30,0,[\"modalTitle\"]]],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[14,0,\"chat-modal-toggle-channel-status__instructions\"],[12],[1,[30,0,[\"instructions\"]]],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,1,\"chat-channel-toggle-btn\"],[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"onStatusChange\"]],[30,0,[\"buttonLabel\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@inline\"],false,[\"d-modal\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/modal/toggle-channel-status.hbs",
    "isStrictMode": false
  });
  let ChatModalToggleChannelStatus = _exports.default = (_class = class ChatModalToggleChannelStatus extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
    }
    get channel() {
      return this.args.model;
    }
    get buttonLabel() {
      if (this.channel?.isClosed) {
        return "chat.channel_settings.open_channel";
      } else {
        return "chat.channel_settings.close_channel";
      }
    }
    get instructions() {
      if (this.channel?.isClosed) {
        return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.channel_open.instructions"));
      } else {
        return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.channel_close.instructions"));
      }
    }
    get modalTitle() {
      if (this.channel?.isClosed) {
        return "chat.channel_open.title";
      } else {
        return "chat.channel_close.title";
      }
    }
    onStatusChange() {
      const status = this.channel.isClosed ? _chatChannel.CHANNEL_STATUSES.open : _chatChannel.CHANNEL_STATUSES.closed;
      return this.chatApi.updateChannelStatus(this.channel.id, status).then(() => {
        this.args.closeModal();
        this.router.transitionTo("chat.channel", ...this.channel.routeModels);
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onStatusChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onStatusChange"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatModalToggleChannelStatus);
});