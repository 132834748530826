define("discourse/plugins/chat/discourse/components/chat/message/avatar", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-message-avatar">
    {{#if @message.chatWebhookEvent.emoji}}
      <ChatEmojiAvatar @emoji={{@message.chatWebhookEvent.emoji}} />
    {{else}}
      <ChatUserAvatar @user={{@message.user}} @avatarSize="medium" />
    {{/if}}
  </div>
  */
  {
    "id": "t5fi35wL",
    "block": "[[[10,0],[14,0,\"chat-message-avatar\"],[12],[1,\"\\n\"],[41,[30,1,[\"chatWebhookEvent\",\"emoji\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@emoji\"],[[30,1,[\"chatWebhookEvent\",\"emoji\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,[[\"@user\",\"@avatarSize\"],[[30,1,[\"user\"]],\"medium\"]],null],[1,\"\\n\"]],[]]],[13]],[\"@message\"],false,[\"if\",\"chat-emoji-avatar\",\"chat-user-avatar\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/message/avatar.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});