define("discourse/plugins/chat/discourse/components/chat-replying-indicator", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/utils", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _utils, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @presenceChannelName}}
    <div
      class={{concat-class
        "chat-replying-indicator"
        (if this.presenceChannel.subscribed "is-subscribed")
      }}
      {{did-insert this.subscribe}}
      {{did-update this.updateSubscription @presenceChannelName}}
      {{will-destroy this.unsubscribe}}
    >
      {{#if this.shouldRender}}
        <span class="chat-replying-indicator__text">{{this.text}}</span>
        <span class="chat-replying-indicator__wave">
          <span class="chat-replying-indicator__dot">.</span>
          <span class="chat-replying-indicator__dot">.</span>
          <span class="chat-replying-indicator__dot">.</span>
        </span>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "7VNw+o1q",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[16,0,[28,[37,1],[\"chat-replying-indicator\",[52,[30,0,[\"presenceChannel\",\"subscribed\"]],\"is-subscribed\"]],null]],[4,[38,2],[[30,0,[\"subscribe\"]]],null],[4,[38,3],[[30,0,[\"updateSubscription\"]],[30,1]],null],[4,[38,4],[[30,0,[\"unsubscribe\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[10,1],[14,0,\"chat-replying-indicator__text\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n      \"],[10,1],[14,0,\"chat-replying-indicator__wave\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@presenceChannelName\"],false,[\"if\",\"concat-class\",\"did-insert\",\"did-update\",\"will-destroy\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-replying-indicator.hbs",
    "isStrictMode": false
  });
  let ChatReplyingIndicator = _exports.default = (_class = class ChatReplyingIndicator extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "presence", _descriptor2, this);
      _initializerDefineProperty(this, "presenceChannel", _descriptor3, this);
    }
    async updateSubscription() {
      await this.unsubscribe();
      await this.subscribe();
    }
    async subscribe() {
      this.presenceChannel = this.presence.getChannel(this.args.presenceChannelName);
      await this.presenceChannel.subscribe();
    }
    async unsubscribe() {
      if (this.presenceChannel?.subscribed) {
        await this.presenceChannel.unsubscribe();
      }
    }
    get users() {
      return this.presenceChannel?.get("users")?.filter(u => u.id !== this.currentUser.id) || [];
    }
    get usernames() {
      return this.users.mapBy("username");
    }
    get text() {
      if (this.usernames.length === 1) {
        return _discourseI18n.default.t("chat.replying_indicator.single_user", {
          username: this.usernames[0]
        });
      }
      if (this.usernames.length < 4) {
        const lastUsername = this.usernames[this.usernames.length - 1];
        const commaSeparatedUsernames = this.usernames.slice(0, this.usernames.length - 1).join(_discourseI18n.default.t("word_connector.comma"));
        return _discourseI18n.default.t("chat.replying_indicator.multiple_users", {
          commaSeparatedUsernames,
          lastUsername
        });
      }
      const commaSeparatedUsernames = this.usernames.slice(0, 2).join(_discourseI18n.default.t("word_connector.comma"));
      return _discourseI18n.default.t("chat.replying_indicator.many_users", {
        commaSeparatedUsernames,
        count: this.usernames.length - 2
      });
    }
    get shouldRender() {
      return (0, _utils.isPresent)(this.usernames);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "presence", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "presenceChannel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateSubscription", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "subscribe", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "subscribe"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unsubscribe", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unsubscribe"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatReplyingIndicator);
});