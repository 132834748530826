define("discourse/plugins/chat/discourse/components/chat/message-creator/channel-row", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _service, _template, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatChannelTitle @channel={{@content.model}} />
  
  {{#if (gt @content.tracking.unreadCount 0)}}
    <div
      class={{concat-class "unread-indicator" (if this.isUrgent "-urgent")}}
    ></div>
  {{/if}}
  
  {{#if this.site.desktopView}}
    <span class="action-indicator">{{this.openChannelLabel}}</span>
  {{/if}}
  */
  {
    "id": "1z+h1Cq0",
    "block": "[[[8,[39,0],null,[[\"@channel\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\\n\"],[41,[28,[37,2],[[30,1,[\"tracking\",\"unreadCount\"]],0],null],[[[1,\"  \"],[10,0],[15,0,[28,[37,3],[\"unread-indicator\",[52,[30,0,[\"isUrgent\"]],\"-urgent\"]],null]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"  \"],[10,1],[14,0,\"action-indicator\"],[12],[1,[30,0,[\"openChannelLabel\"]]],[13],[1,\"\\n\"]],[]],null]],[\"@content\"],false,[\"chat-channel-title\",\"if\",\"gt\",\"concat-class\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/message-creator/channel-row.hbs",
    "isStrictMode": false
  });
  let ChatMessageCreatorChannelRow = _exports.default = (_class = class ChatMessageCreatorChannelRow extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get openChannelLabel() {
      return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.new_message_modal.open_channel"));
    }
    get isUrgent() {
      return this.args.content.model.isDirectMessageChannel || this.args.content.model.isCategoryChannel && this.args.content.model.tracking.mentionCount > 0;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMessageCreatorChannelRow);
});