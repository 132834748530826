define("discourse/plugins/chat/discourse/components/chat-message-separator-new", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and @message.newest (not @message.formattedFirstMessageDate))}}
    <div class="chat-message-separator-new">
      <div class="chat-message-separator__text-container">
        <span class="chat-message-separator__text">
          {{i18n "chat.last_visit"}}
        </span>
      </div>
  
      <div class="chat-message-separator__line-container">
        <div class="chat-message-separator__line"></div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "0fHoqVRL",
    "block": "[[[41,[28,[37,1],[[30,1,[\"newest\"]],[28,[37,2],[[30,1,[\"formattedFirstMessageDate\"]]],null]],null],[[[1,\"  \"],[10,0],[14,0,\"chat-message-separator-new\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-message-separator__text-container\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"chat-message-separator__text\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"chat.last_visit\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"chat-message-separator__line-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-separator__line\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@message\"],false,[\"if\",\"and\",\"not\",\"i18n\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-message-separator-new.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});