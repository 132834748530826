define("discourse/plugins/chat/discourse/components/chat/message/left-gutter", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-message-left-gutter">
    {{#if @message.reviewableId}}
      <LinkTo
        @route="review.show"
        @model={{@message.reviewableId}}
        class="chat-message-left-gutter__flag"
      >
        {{d-icon "flag" title="chat.flagged"}}
      </LinkTo>
    {{else if (eq @message.userFlagStatus 0)}}
      <div class="chat-message-left-gutter__flag">
        {{d-icon "flag" title="chat.you_flagged"}}
      </div>
    {{else if this.site.desktopView}}
      <span class="chat-message-left-gutter__date">
        {{format-chat-date @message "tiny"}}
      </span>
    {{/if}}
    {{#if @message.bookmark}}
      <span class="chat-message-left-gutter__bookmark">
        <BookmarkIcon @bookmark={{@message.bookmark}} />
      </span>
    {{/if}}
  </div>
  */
  {
    "id": "aZfQa7lM",
    "block": "[[[10,0],[14,0,\"chat-message-left-gutter\"],[12],[1,\"\\n\"],[41,[30,1,[\"reviewableId\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"chat-message-left-gutter__flag\"]],[[\"@route\",\"@model\"],[\"review.show\",[30,1,[\"reviewableId\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"flag\"],[[\"title\"],[\"chat.flagged\"]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,1,[\"userFlagStatus\"]],0],null],[[[1,\"    \"],[10,0],[14,0,\"chat-message-left-gutter__flag\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"flag\"],[[\"title\"],[\"chat.you_flagged\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"    \"],[10,1],[14,0,\"chat-message-left-gutter__date\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[[30,1],\"tiny\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]]],[]]],[41,[30,1,[\"bookmark\"]],[[[1,\"    \"],[10,1],[14,0,\"chat-message-left-gutter__bookmark\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@bookmark\"],[[30,1,[\"bookmark\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@message\"],false,[\"if\",\"link-to\",\"d-icon\",\"eq\",\"format-chat-date\",\"bookmark-icon\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/message/left-gutter.hbs",
    "isStrictMode": false
  });
  let ChatMessageLeftGutter = _exports.default = (_class = class ChatMessageLeftGutter extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMessageLeftGutter);
});