define("discourse/plugins/chat/discourse/components/chat/message-creator/user-row", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _service, _template, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatUserAvatar @user={{@content.model}} />
  <ChatUserDisplayName @user={{@content.model}} />
  
  {{#if (gt @content.tracking.unreadCount 0)}}
    <div class="unread-indicator -urgent"></div>
  {{/if}}
  
  {{user-status @content.model currentUser=this.currentUser}}
  
  {{#unless @content.enabled}}
    <span class="disabled-text">
      {{i18n "chat.new_message_modal.disabled_user"}}
    </span>
  {{/unless}}
  
  {{#if @selected}}
    {{#if this.site.mobileView}}
      <span class="selection-indicator -add">
        {{d-icon "check"}}
      </span>
    {{else}}
      <span
        class={{concat-class "selection-indicator" (if @active "-remove" "-add")}}
      >
        {{d-icon (if @active "times" "check")}}
      </span>
    {{/if}}
  {{else}}
    {{#if this.site.desktopView}}
      {{#if @hasSelectedUsers}}
        <span class="action-indicator">{{this.addUserLabel}}</span>
      {{else}}
        <span class="action-indicator">{{this.openChannelLabel}}</span>
      {{/if}}
    {{/if}}
  {{/if}}
  */
  {
    "id": "gxqW91gC",
    "block": "[[[8,[39,0],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,1,[\"tracking\",\"unreadCount\"]],0],null],[[[1,\"  \"],[10,0],[14,0,\"unread-indicator -urgent\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,4],[[30,1,[\"model\"]]],[[\"currentUser\"],[[30,0,[\"currentUser\"]]]]]],[1,\"\\n\\n\"],[41,[51,[30,1,[\"enabled\"]]],[[[1,\"  \"],[10,1],[14,0,\"disabled-text\"],[12],[1,\"\\n    \"],[1,[28,[35,6],[\"chat.new_message_modal.disabled_user\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2],[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"    \"],[10,1],[14,0,\"selection-indicator -add\"],[12],[1,\"\\n      \"],[1,[28,[35,7],[\"check\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[15,0,[28,[37,8],[\"selection-indicator\",[52,[30,3],\"-remove\",\"-add\"]],null]],[12],[1,\"\\n      \"],[1,[28,[35,7],[[52,[30,3],\"times\",\"check\"]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"site\",\"desktopView\"]],[[[41,[30,4],[[[1,\"      \"],[10,1],[14,0,\"action-indicator\"],[12],[1,[30,0,[\"addUserLabel\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"action-indicator\"],[12],[1,[30,0,[\"openChannelLabel\"]]],[13],[1,\"\\n\"]],[]]]],[]],null]],[]]]],[\"@content\",\"@selected\",\"@active\",\"@hasSelectedUsers\"],false,[\"chat-user-avatar\",\"chat-user-display-name\",\"if\",\"gt\",\"user-status\",\"unless\",\"i18n\",\"d-icon\",\"concat-class\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/message-creator/user-row.hbs",
    "isStrictMode": false
  });
  let ChatMessageCreatorUserRow = _exports.default = (_class = class ChatMessageCreatorUserRow extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
    }
    get openChannelLabel() {
      return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.new_message_modal.open_channel"));
    }
    get addUserLabel() {
      return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.new_message_modal.add_user_short"));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMessageCreatorUserRow);
});