define("discourse/plugins/chat/discourse/components/chat/composer/button", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-composer-button__wrapper">
    <button type="button" class="chat-composer-button" ...attributes>
      {{d-icon @icon}}
    </button>
  </div>
  */
  {
    "id": "UWG6Qkfs",
    "block": "[[[10,0],[14,0,\"chat-composer-button__wrapper\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,4,\"button\"],[24,0,\"chat-composer-button\"],[17,1],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,2]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@icon\"],false,[\"d-icon\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/composer/button.hbs",
    "isStrictMode": false
  });
  class ChatComposerButton extends _component2.default {}
  _exports.default = ChatComposerButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatComposerButton);
});