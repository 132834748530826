define("discourse/plugins/chat/discourse/components/chat-drawer/header/close-button", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @icon="times"
    @action={{@close}}
    @title="chat.close"
    class="btn-flat btn-link chat-drawer-header__close-btn"
  />
  */
  {
    "id": "GvR89FJy",
    "block": "[[[8,[39,0],[[24,0,\"btn-flat btn-link chat-drawer-header__close-btn\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,1],\"chat.close\"]],null]],[\"@close\"],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-drawer/header/close-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});