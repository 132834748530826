define("discourse/plugins/chat/discourse/components/chat-channel-preview-card", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _component2, _service, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{concat-class
      "chat-channel-preview-card"
      (unless this.hasDescription "-no-description")
      (unless this.showJoinButton "-no-button")
    }}
  >
    <ChatChannelTitle @channel={{@channel}} />
    {{#if this.hasDescription}}
      <p class="chat-channel-preview-card__description">
        {{@channel.description}}
      </p>
    {{/if}}
    {{#if this.showJoinButton}}
      <ToggleChannelMembershipButton
        @channel={{@channel}}
        @options={{hash joinClass="btn-primary"}}
      />
    {{/if}}
    <LinkTo @route="chat.browse" class="chat-channel-preview-card__browse-all">
      {{i18n "chat.browse_all_channels"}}
    </LinkTo>
  </div>
  */
  {
    "id": "BCZbso3D",
    "block": "[[[10,0],[15,0,[28,[37,0],[\"chat-channel-preview-card\",[52,[51,[30,0,[\"hasDescription\"]]],\"-no-description\"],[52,[51,[30,0,[\"showJoinButton\"]]],\"-no-button\"]],null]],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\"],[41,[30,0,[\"hasDescription\"]],[[[1,\"    \"],[10,2],[14,0,\"chat-channel-preview-card__description\"],[12],[1,\"\\n      \"],[1,[30,1,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showJoinButton\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@channel\",\"@options\"],[[30,1],[28,[37,5],null,[[\"joinClass\"],[\"btn-primary\"]]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,6],[[24,0,\"chat-channel-preview-card__browse-all\"]],[[\"@route\"],[\"chat.browse\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,7],[\"chat.browse_all_channels\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@channel\"],false,[\"concat-class\",\"unless\",\"chat-channel-title\",\"if\",\"toggle-channel-membership-button\",\"hash\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-channel-preview-card.hbs",
    "isStrictMode": false
  });
  let ChatChannelPreviewCard = _exports.default = (_class = class ChatChannelPreviewCard extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
    }
    get showJoinButton() {
      return this.args.channel?.isOpen && this.args.channel?.canJoin;
    }
    get hasDescription() {
      return !(0, _utils.isEmpty)(this.args.channel?.description);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatChannelPreviewCard);
});