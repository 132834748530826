define("discourse/plugins/chat/discourse/components/chat/modal/delete-channel", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/lib/ajax-error", "discourse-common/lib/later", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _template, _utils, _ajaxError, _later, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="chat-modal-delete-channel"
    @inline={{@inline}}
    @title={{i18n "chat.channel_delete.title"}}
    @flash={{this.flash}}
    @flashType={{this.flashType}}
  >
    <:body>
      <p class="chat-modal-delete-channel__instructions">
        {{this.instructionsText}}
      </p>
  
      <TextField
        @value={{this.channelNameConfirmation}}
        @id="channel-delete-confirm-name"
        @placeholderKey="chat.channel_delete.confirm_channel_name"
        @autocorrect="off"
        @autocapitalize="off"
      />
    </:body>
    <:footer>
      <DButton
        @disabled={{this.buttonDisabled}}
        @action={{this.deleteChannel}}
        @label="chat.channel_delete.confirm"
        id="chat-confirm-delete-channel"
        class="btn-danger"
      />
      <DButton @label="cancel" @action={{@closeModal}} class="btn-flat" />
    </:footer>
  </DModal>
  */
  {
    "id": "PzSml1dG",
    "block": "[[[8,[39,0],[[24,0,\"chat-modal-delete-channel\"]],[[\"@closeModal\",\"@inline\",\"@title\",\"@flash\",\"@flashType\"],[[30,1],[30,2],[28,[37,1],[\"chat.channel_delete.title\"],null],[30,0,[\"flash\"]],[30,0,[\"flashType\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[14,0,\"chat-modal-delete-channel__instructions\"],[12],[1,\"\\n      \"],[1,[30,0,[\"instructionsText\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@value\",\"@id\",\"@placeholderKey\",\"@autocorrect\",\"@autocapitalize\"],[[30,0,[\"channelNameConfirmation\"]],\"channel-delete-confirm-name\",\"chat.channel_delete.confirm_channel_name\",\"off\",\"off\"]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,1,\"chat-confirm-delete-channel\"],[24,0,\"btn-danger\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"deleteChannel\"]],\"chat.channel_delete.confirm\"]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@inline\"],false,[\"d-modal\",\"i18n\",\"text-field\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/modal/delete-channel.hbs",
    "isStrictMode": false
  });
  let ChatModalDeleteChannel = _exports.default = (_class = class ChatModalDeleteChannel extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "channelNameConfirmation", _descriptor3, this);
      _initializerDefineProperty(this, "deleting", _descriptor4, this);
      _initializerDefineProperty(this, "confirmed", _descriptor5, this);
      _initializerDefineProperty(this, "flash", _descriptor6, this);
      _initializerDefineProperty(this, "flashType", _descriptor7, this);
    }
    get channel() {
      return this.args.model.channel;
    }
    get buttonDisabled() {
      if (this.deleting || this.confirmed) {
        return true;
      }
      if ((0, _utils.isEmpty)(this.channelNameConfirmation) || this.channelNameConfirmation.toLowerCase() !== this.channel.title.toLowerCase()) {
        return true;
      }
      return false;
    }
    get instructionsText() {
      return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.channel_delete.instructions", {
        name: this.channel.escapedTitle
      }));
    }
    deleteChannel() {
      this.deleting = true;
      return this.chatApi.destroyChannel(this.channel.id, this.channelNameConfirmation).then(() => {
        this.confirmed = true;
        this.flash = _discourseI18n.default.t("chat.channel_delete.process_started");
        this.flashType = "success";
        (0, _later.default)(() => {
          this.args.closeModal();
          this.router.transitionTo("chat");
        }, 3000);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.deleting = false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "channelNameConfirmation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "deleting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "flashType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteChannel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatModalDeleteChannel);
});