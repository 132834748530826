define("discourse/plugins/chat/discourse/services/chat-channel-pane-subscriptions-manager", ["exports", "@glimmer/tracking", "@ember/service", "@ember-compat/tracked-built-ins", "discourse/plugins/chat/discourse/models/chat-notice", "discourse/plugins/chat/discourse/models/chat-thread-preview", "discourse/plugins/chat/discourse/services/chat-pane-base-subscriptions-manager"], function (_exports, _tracking, _service, _trackedBuiltIns, _chatNotice, _chatThreadPreview, _chatPaneBaseSubscriptionsManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelPaneSubscriptionsManager = _exports.default = (_class = class ChatChannelPaneSubscriptionsManager extends _chatPaneBaseSubscriptionsManager.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "notices", _descriptor3, this);
    }
    beforeSubscribe(model) {
      this.messageBusChannel = `/chat/${model.id}`;
      this.messageBusLastId = model.channelMessageBusLastId;
    }
    afterMessage(model, _, __, lastMessageBusId) {
      model.channelMessageBusLastId = lastMessageBusId;
    }
    handleSentMessage() {
      return;
    }
    handleNotice(data) {
      this.notices.pushObject(_chatNotice.default.create(data));
    }
    clearNotice(notice) {
      this.notices.removeObject(notice);
    }
    handleThreadOriginalMessageUpdate(data) {
      const message = this.messagesManager.findMessage(data.original_message_id);
      if (message?.thread) {
        message.thread.preview = _chatThreadPreview.default.create(data.preview);
      }
    }
    _afterDeleteMessage(targetMsg, data) {
      if (this.model.currentUserMembership.lastReadMessageId === targetMsg.id) {
        this.model.currentUserMembership.lastReadMessageId = data.latest_not_deleted_message_id;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notices", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _trackedBuiltIns.TrackedArray();
    }
  })), _class);
});