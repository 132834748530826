define("discourse/plugins/chat/discourse/components/chat-message-separator-date", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @message.formattedFirstMessageDate}}
    <div
      class={{concat-class
        "chat-message-separator-date"
        (if @message.newest "with-last-visit")
      }}
      role="button"
      {{on "click" this.onDateClick passive=true}}
    >
      <div
        class="chat-message-separator__text-container"
        {{chat/track-message-separator-date}}
      >
        <span class="chat-message-separator__text">
          {{@message.formattedFirstMessageDate}}
  
          {{#if @message.newest}}
            <span class="chat-message-separator__last-visit">
              <span class="chat-message-separator__last-visit-separator">-</span>
              {{i18n "chat.last_visit"}}
            </span>
          {{/if}}
        </span>
      </div>
    </div>
  
    <div class="chat-message-separator__line-container">
      <div class="chat-message-separator__line"></div>
    </div>
  {{/if}}
  */
  {
    "id": "9p1ZIO+U",
    "block": "[[[41,[30,1,[\"formattedFirstMessageDate\"]],[[[1,\"  \"],[11,0],[16,0,[28,[37,1],[\"chat-message-separator-date\",[52,[30,1,[\"newest\"]],\"with-last-visit\"]],null]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,0,[\"onDateClick\"]]],[[\"passive\"],[true]]],[12],[1,\"\\n    \"],[11,0],[24,0,\"chat-message-separator__text-container\"],[4,[38,3],null,null],[12],[1,\"\\n      \"],[10,1],[14,0,\"chat-message-separator__text\"],[12],[1,\"\\n        \"],[1,[30,1,[\"formattedFirstMessageDate\"]]],[1,\"\\n\\n\"],[41,[30,1,[\"newest\"]],[[[1,\"          \"],[10,1],[14,0,\"chat-message-separator__last-visit\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"chat-message-separator__last-visit-separator\"],[12],[1,\"-\"],[13],[1,\"\\n            \"],[1,[28,[35,4],[\"chat.last_visit\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"chat-message-separator__line-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-message-separator__line\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@message\"],false,[\"if\",\"concat-class\",\"on\",\"chat/track-message-separator-date\",\"i18n\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-message-separator-date.hbs",
    "isStrictMode": false
  });
  let ChatMessageSeparatorDate = _exports.default = (_class = class ChatMessageSeparatorDate extends _component2.default {
    onDateClick() {
      return this.args.fetchMessagesByDate?.(this.args.message.firstMessageOfTheDayAt);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onDateClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDateClick"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMessageSeparatorDate);
});