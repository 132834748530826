define("discourse/plugins/chat/discourse/components/chat-emoji-avatar", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-emoji-avatar">
    <div class="chat-emoji-avatar-container">
      {{replace-emoji @emoji}}
    </div>
  </div>
  */
  {
    "id": "dtSGV+5d",
    "block": "[[[10,0],[14,0,\"chat-emoji-avatar\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"chat-emoji-avatar-container\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,1]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@emoji\"],false,[\"replace-emoji\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-emoji-avatar.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});