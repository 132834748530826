define("discourse/plugins/chat/discourse/components/toggle-channel-membership-button", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajaxError, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @channel.currentUserMembership.following}}
    <DButton
      @action={{this.onLeaveChannel}}
      @translatedLabel={{this.label}}
      @translatedTitle={{this.options.leaveTitle}}
      @icon={{this.options.leaveIcon}}
      @disabled={{this.isLoading}}
      class={{concat-class
        "toggle-channel-membership-button -leave btn-flat"
        this.options.leaveClass
      }}
    />
  {{else}}
    <DButton
      @action={{this.onJoinChannel}}
      @translatedLabel={{this.label}}
      @translatedTitle={{this.options.joinTitle}}
      @icon={{this.options.joinIcon}}
      @disabled={{this.isLoading}}
      class={{concat-class
        "toggle-channel-membership-button -join"
        this.options.joinClass
      }}
    />
  {{/if}}
  */
  {
    "id": "CWfAxj6k",
    "block": "[[[41,[30,1,[\"currentUserMembership\",\"following\"]],[[[1,\"  \"],[8,[39,1],[[16,0,[28,[37,2],[\"toggle-channel-membership-button -leave btn-flat\",[30,0,[\"options\",\"leaveClass\"]]],null]]],[[\"@action\",\"@translatedLabel\",\"@translatedTitle\",\"@icon\",\"@disabled\"],[[30,0,[\"onLeaveChannel\"]],[30,0,[\"label\"]],[30,0,[\"options\",\"leaveTitle\"]],[30,0,[\"options\",\"leaveIcon\"]],[30,0,[\"isLoading\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],[[16,0,[28,[37,2],[\"toggle-channel-membership-button -join\",[30,0,[\"options\",\"joinClass\"]]],null]]],[[\"@action\",\"@translatedLabel\",\"@translatedTitle\",\"@icon\",\"@disabled\"],[[30,0,[\"onJoinChannel\"]],[30,0,[\"label\"]],[30,0,[\"options\",\"joinTitle\"]],[30,0,[\"options\",\"joinIcon\"]],[30,0,[\"isLoading\"]]]],null],[1,\"\\n\"]],[]]]],[\"@channel\"],false,[\"if\",\"d-button\",\"concat-class\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/toggle-channel-membership-button.hbs",
    "isStrictMode": false
  });
  let ToggleChannelMembershipButton = _exports.default = (_class = class ToggleChannelMembershipButton extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "isLoading", _descriptor2, this);
      _defineProperty(this, "onToggle", null);
      _defineProperty(this, "options", {});
      this.options = {
        labelType: "normal",
        joinTitle: _discourseI18n.default.t("chat.channel_settings.join_channel"),
        joinIcon: "",
        joinClass: "",
        leaveTitle: _discourseI18n.default.t("chat.channel_settings.leave_channel"),
        leaveIcon: "",
        leaveClass: "",
        ...this.args.options
      };
    }
    get label() {
      if (this.options.labelType === "none") {
        return "";
      }
      if (this.options.labelType === "short") {
        if (this.args.channel.currentUserMembership.following) {
          return _discourseI18n.default.t("chat.channel_settings.leave");
        } else {
          return _discourseI18n.default.t("chat.channel_settings.join");
        }
      }
      if (this.args.channel.currentUserMembership.following) {
        return _discourseI18n.default.t("chat.channel_settings.leave_channel");
      } else {
        return _discourseI18n.default.t("chat.channel_settings.join_channel");
      }
    }
    onJoinChannel() {
      this.isLoading = true;
      return this.chat.followChannel(this.args.channel).then(() => {
        this.onToggle?.();
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.isLoading = false;
      });
    }
    onLeaveChannel() {
      this.isLoading = true;
      return this.chat.unfollowChannel(this.args.channel).then(() => {
        this.onToggle?.();
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.isLoading = false;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onJoinChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onJoinChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onLeaveChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onLeaveChannel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ToggleChannelMembershipButton);
});