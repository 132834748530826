define("discourse/plugins/chat/discourse/components/chat/thread/header", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse/lib/notification-levels", "discourse/plugins/chat/discourse/components/chat/modal/thread-settings", "discourse/plugins/chat/discourse/models/user-chat-thread-membership", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajaxError, _notificationLevels, _threadSettings, _userChatThreadMembership, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-thread-header">
    <div class="chat-thread-header__left-buttons">
      {{#if @thread}}
        <LinkTo
          class="chat-thread__back-to-previous-route btn-flat btn btn-icon no-text"
          @route={{this.backLink.route}}
          @models={{this.backLink.models}}
          title={{i18n "chat.return_to_threads_list"}}
        >
          <Chat::Thread::HeaderUnreadIndicator @channel={{@thread.channel}} />
          {{d-icon "chevron-left"}}
        </LinkTo>
      {{/if}}
    </div>
  
    <span class="chat-thread-header__label overflow-ellipsis">
      {{replace-emoji @thread.title}}
    </span>
  
    <div
      class={{concat-class
        "chat-thread-header__buttons"
        (if this.persistedNotificationLevel "-persisted")
      }}
    >
      <ThreadNotificationsButton
        @value={{this.threadNotificationLevel}}
        @onChange={{this.updateThreadNotificationLevel}}
      />
      {{#if this.canChangeThreadSettings}}
        <DButton
          @action={{this.openThreadSettings}}
          @icon="cog"
          @title="chat.thread.settings"
          class="btn-flat chat-thread-header__settings"
        />
      {{/if}}
      <LinkTo
        class="chat-thread__close btn-flat btn btn-icon no-text"
        @route="chat.channel"
        @models={{@thread.channel.routeModels}}
        title={{i18n "chat.thread.close"}}
      >
        {{d-icon "times"}}
      </LinkTo>
    </div>
  </div>
  */
  {
    "id": "yIXnh4Vy",
    "block": "[[[10,0],[14,0,\"chat-thread-header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"chat-thread-header__left-buttons\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[39,1],[[24,0,\"chat-thread__back-to-previous-route btn-flat btn btn-icon no-text\"],[16,\"title\",[28,[37,2],[\"chat.return_to_threads_list\"],null]]],[[\"@route\",\"@models\"],[[30,0,[\"backLink\",\"route\"]],[30,0,[\"backLink\",\"models\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@channel\"],[[30,1,[\"channel\"]]]],null],[1,\"\\n        \"],[1,[28,[35,4],[\"chevron-left\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,1],[14,0,\"chat-thread-header__label overflow-ellipsis\"],[12],[1,\"\\n    \"],[1,[28,[35,5],[[30,1,[\"title\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[15,0,[28,[37,6],[\"chat-thread-header__buttons\",[52,[30,0,[\"persistedNotificationLevel\"]],\"-persisted\"]],null]],[12],[1,\"\\n    \"],[8,[39,7],null,[[\"@value\",\"@onChange\"],[[30,0,[\"threadNotificationLevel\"]],[30,0,[\"updateThreadNotificationLevel\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"canChangeThreadSettings\"]],[[[1,\"      \"],[8,[39,8],[[24,0,\"btn-flat chat-thread-header__settings\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"openThreadSettings\"]],\"cog\",\"chat.thread.settings\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,1],[[24,0,\"chat-thread__close btn-flat btn btn-icon no-text\"],[16,\"title\",[28,[37,2],[\"chat.thread.close\"],null]]],[[\"@route\",\"@models\"],[\"chat.channel\",[30,1,[\"channel\",\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,4],[\"times\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@thread\"],false,[\"if\",\"link-to\",\"i18n\",\"chat/thread/header-unread-indicator\",\"d-icon\",\"replace-emoji\",\"concat-class\",\"thread-notifications-button\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/thread/header.hbs",
    "isStrictMode": false
  });
  let ChatThreadHeader = _exports.default = (_class = class ChatThreadHeader extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor4, this);
      _initializerDefineProperty(this, "chatHistory", _descriptor5, this);
      _initializerDefineProperty(this, "site", _descriptor6, this);
      _initializerDefineProperty(this, "modal", _descriptor7, this);
      _initializerDefineProperty(this, "persistedNotificationLevel", _descriptor8, this);
    }
    get backLink() {
      let route;
      if (this.chatHistory.previousRoute?.name === "chat.channel.index" && this.site.mobileView) {
        route = "chat.channel.index";
      } else {
        route = "chat.channel.threads";
      }
      return {
        route,
        models: this.args.channel.routeModels
      };
    }
    get canChangeThreadSettings() {
      if (!this.args.thread) {
        return false;
      }
      return this.currentUser.staff || this.currentUser.id === this.args.thread.originalMessage.user.id;
    }
    get threadNotificationLevel() {
      return this.membership?.notificationLevel || _notificationLevels.NotificationLevels.REGULAR;
    }
    get membership() {
      return this.args.thread.currentUserMembership;
    }
    openThreadSettings() {
      this.modal.show(_threadSettings.default, {
        model: this.args.thread
      });
    }
    updateThreadNotificationLevel(newNotificationLevel) {
      this.persistedNotificationLevel = false;
      let currentNotificationLevel;
      if (this.membership) {
        currentNotificationLevel = this.membership.notificationLevel;
        this.membership.notificationLevel = newNotificationLevel;
      } else {
        this.args.thread.currentUserMembership = _userChatThreadMembership.default.create({
          notification_level: newNotificationLevel,
          last_read_message_id: null
        });
      }
      this.chatApi.updateCurrentUserThreadNotificationsSettings(this.args.thread.channel.id, this.args.thread.id, {
        notificationLevel: newNotificationLevel
      }).then(response => {
        this.membership.last_read_message_id = response.membership.last_read_message_id;
        this.persistedNotificationLevel = true;
      }).catch(err => {
        this.membership.notificationLevel = currentNotificationLevel;
        (0, _ajaxError.popupAjaxError)(err);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chatHistory", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "persistedNotificationLevel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openThreadSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openThreadSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateThreadNotificationLevel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateThreadNotificationLevel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatThreadHeader);
});