define("discourse/plugins/chat/discourse/components/chat-mention-warnings", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template", "discourse-common/lib/get-url", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _service, _template, _getUrl, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    <div class="chat-mention-warnings">
      <div class="chat-mention-warning__icon">
        {{d-icon "exclamation-triangle"}}
      </div>
      <div class="chat-mention-warning__text">
        <div class="chat-mention-warning__header">
          {{this.warningHeaderText}}
        </div>
        <ul class={{this.listStyleClass}}>
          {{#if this.hasTooManyMentions}}
            <li>{{this.tooManyMentionsBody}}</li>
          {{else}}
            {{#if this.channelWideMentionDisallowed}}
              <li>{{i18n
                  "chat.mention_warning.channel_wide_mentions_disallowed"
                }}</li>
            {{/if}}
            {{#if this.hasUnreachableGroupMentions}}
              <li>{{this.unreachableBody}}</li>
            {{/if}}
            {{#if this.hasOverMembersLimitGroupMentions}}
              <li>{{this.overMembersLimitBody}}</li>
            {{/if}}
          {{/if}}
        </ul>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "OyAiEEsN",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[10,0],[14,0,\"chat-mention-warnings\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-mention-warning__icon\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"exclamation-triangle\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"chat-mention-warning__text\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-mention-warning__header\"],[12],[1,\"\\n        \"],[1,[30,0,[\"warningHeaderText\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"ul\"],[15,0,[30,0,[\"listStyleClass\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasTooManyMentions\"]],[[[1,\"          \"],[10,\"li\"],[12],[1,[30,0,[\"tooManyMentionsBody\"]]],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"channelWideMentionDisallowed\"]],[[[1,\"            \"],[10,\"li\"],[12],[1,[28,[35,2],[\"chat.mention_warning.channel_wide_mentions_disallowed\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasUnreachableGroupMentions\"]],[[[1,\"            \"],[10,\"li\"],[12],[1,[30,0,[\"unreachableBody\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasOverMembersLimitGroupMentions\"]],[[[1,\"            \"],[10,\"li\"],[12],[1,[30,0,[\"overMembersLimitBody\"]]],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-mention-warnings.hbs",
    "isStrictMode": false
  });
  let ChatMentionWarnings = _exports.default = (_class = class ChatMentionWarnings extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "chatComposerWarningsTracker", _descriptor3, this);
    }
    get unreachableGroupMentions() {
      return this.chatComposerWarningsTracker.unreachableGroupMentions;
    }
    get overMembersLimitGroupMentions() {
      return this.chatComposerWarningsTracker.overMembersLimitGroupMentions;
    }
    get hasTooManyMentions() {
      return this.chatComposerWarningsTracker.tooManyMentions;
    }
    get channelWideMentionDisallowed() {
      return this.chatComposerWarningsTracker.channelWideMentionDisallowed;
    }
    get mentionsCount() {
      return this.chatComposerWarningsTracker.mentionsCount;
    }
    get unreachableGroupMentionsCount() {
      return this.unreachableGroupMentions.length;
    }
    get overMembersLimitMentionsCount() {
      return this.overMembersLimitGroupMentions.length;
    }
    get hasUnreachableGroupMentions() {
      return this.unreachableGroupMentionsCount > 0;
    }
    get hasOverMembersLimitGroupMentions() {
      return this.overMembersLimitMentionsCount > 0;
    }
    get warningsCount() {
      return this.unreachableGroupMentionsCount + this.overMembersLimitMentionsCount;
    }
    get show() {
      return this.hasTooManyMentions || this.channelWideMentionDisallowed || this.hasUnreachableGroupMentions || this.hasOverMembersLimitGroupMentions;
    }
    get listStyleClass() {
      if (this.hasTooManyMentions) {
        return "chat-mention-warnings-list__simple";
      }
      if (this.warningsCount > 1) {
        return "chat-mention-warnings-list__multiple";
      } else {
        return "chat-mention-warnings-list__simple";
      }
    }
    get warningHeaderText() {
      if (this.mentionsCount <= this.warningsCount || this.hasTooManyMentions) {
        return _discourseI18n.default.t("chat.mention_warning.groups.header.all");
      } else {
        return _discourseI18n.default.t("chat.mention_warning.groups.header.some");
      }
    }
    get tooManyMentionsBody() {
      if (!this.hasTooManyMentions) {
        return;
      }
      if (this.currentUser.admin) {
        return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.mention_warning.too_many_mentions_admin", {
          count: this.siteSettings.max_mentions_per_chat_message,
          siteSettingUrl: (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=max_mentions_per_chat_message")
        }));
      } else {
        return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.mention_warning.too_many_mentions", {
          count: this.siteSettings.max_mentions_per_chat_message
        }));
      }
    }
    get unreachableBody() {
      if (!this.hasUnreachableGroupMentions) {
        return;
      }
      switch (this.unreachableGroupMentionsCount) {
        case 1:
          return _discourseI18n.default.t("chat.mention_warning.groups.unreachable_1", {
            group: this.unreachableGroupMentions[0]
          });
        case 2:
          return _discourseI18n.default.t("chat.mention_warning.groups.unreachable_2", {
            group1: this.unreachableGroupMentions[0],
            group2: this.unreachableGroupMentions[1]
          });
        default:
          return _discourseI18n.default.t("chat.mention_warning.groups.unreachable_multiple", {
            group: this.unreachableGroupMentions[0],
            count: this.unreachableGroupMentionsCount - 1
          });
      }
    }
    get overMembersLimitBody() {
      if (!this.hasOverMembersLimitGroupMentions) {
        return;
      }
      return (0, _template.htmlSafe)(_discourseI18n.default.messageFormat("chat.mention_warning.groups.too_many_members_MF", {
        groupCount: this.overMembersLimitMentionsCount,
        isAdmin: this.currentUser.admin,
        siteSettingUrl: (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=max_users_notified_per_group_mention"),
        notificationLimit: this.siteSettings.max_users_notified_per_group_mention,
        group1: this.overMembersLimitGroupMentions[0],
        group2: this.overMembersLimitGroupMentions[1]
      }));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatComposerWarningsTracker", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMentionWarnings);
});