define("discourse/plugins/chat/discourse/components/chat/composer/separator", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-composer-separator"></div>
  */
  {
    "id": "eEB+qb1x",
    "block": "[[[10,0],[14,0,\"chat-composer-separator\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/composer/separator.hbs",
    "isStrictMode": false
  });
  class ChatComposerSeparator extends _component2.default {}
  _exports.default = ChatComposerSeparator;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatComposerSeparator);
});