define("discourse/plugins/chat/discourse/components/chat/modal/channel-summary", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajaxError, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="chat-modal-channel-summary"
    @title={{i18n "chat.summarization.title"}}
  >
    <:body>
      <span>{{i18n "chat.summarization.description"}}</span>
      <ComboBox
        @value={{this.sinceHours}}
        @content={{this.sinceOptions}}
        @onChange={{this.summarize}}
        @valueProperty="value"
        @class="summarization-since"
      />
      <ConditionalLoadingSection @isLoading={{this.loading}}>
        <p class="summary-area">{{this.summary}}</p>
      </ConditionalLoadingSection>
    </:body>
    <:footer>
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "k3KLu8u/",
    "block": "[[[8,[39,0],[[24,0,\"chat-modal-channel-summary\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"chat.summarization.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,1],[12],[1,[28,[35,1],[\"chat.summarization.description\"],null]],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@content\",\"@onChange\",\"@valueProperty\",\"@class\"],[[30,0,[\"sinceHours\"]],[30,0,[\"sinceOptions\"]],[30,0,[\"summarize\"]],\"value\",\"summarization-since\"]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@isLoading\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,2],[14,0,\"summary-area\"],[12],[1,[30,0,[\"summary\"]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"combo-box\",\"conditional-loading-section\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/modal/channel-summary.hbs",
    "isStrictMode": false
  });
  let ChatModalChannelSummary = _exports.default = (_class = class ChatModalChannelSummary extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "sinceHours", _descriptor2, this);
      _initializerDefineProperty(this, "loading", _descriptor3, this);
      _initializerDefineProperty(this, "summary", _descriptor4, this);
      _defineProperty(this, "availableSummaries", {});
      _defineProperty(this, "sinceOptions", [1, 3, 6, 12, 24, 72, 168].map(hours => {
        return {
          name: _discourseI18n.default.t("chat.summarization.since", {
            count: hours
          }),
          value: hours
        };
      }));
    }
    get channelId() {
      return this.args.model.channelId;
    }
    summarize(since) {
      this.sinceHours = since;
      this.loading = true;
      if (this.availableSummaries[since]) {
        this.summary = this.availableSummaries[since];
        this.loading = false;
        return;
      }
      return this.chatApi.summarize(this.channelId, {
        since
      }).then(data => {
        this.availableSummaries[this.sinceHours] = data.summary;
        this.summary = this.availableSummaries[this.sinceHours];
      }).catch(_ajaxError.popupAjaxError).finally(() => this.loading = false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sinceHours", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "summary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "summarize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "summarize"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatModalChannelSummary);
});