define("discourse/plugins/chat/discourse/components/chat/thread-list/header", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-thread-header">
    <span class="chat-thread-header__label">
      {{replace-emoji (i18n "chat.threads.list")}}
    </span>
  
    <div class="chat-thread-header__buttons">
      <LinkTo
        class="chat-thread__close btn-flat btn btn-icon no-text"
        @route="chat.channel"
        @models={{@channel.routeModels}}
        title={{i18n "chat.thread.close"}}
      >
        {{d-icon "times"}}
      </LinkTo>
    </div>
  </div>
  */
  {
    "id": "5OYm993C",
    "block": "[[[10,0],[14,0,\"chat-thread-header\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"chat-thread-header__label\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[28,[37,1],[\"chat.threads.list\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"chat-thread-header__buttons\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"chat-thread__close btn-flat btn btn-icon no-text\"],[16,\"title\",[28,[37,1],[\"chat.thread.close\"],null]]],[[\"@route\",\"@models\"],[\"chat.channel\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,3],[\"times\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@channel\"],false,[\"replace-emoji\",\"i18n\",\"link-to\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/thread-list/header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});