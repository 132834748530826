define("discourse/plugins/chat/discourse/components/chat-channel-archive-status", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "@ember/utils", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _template, _utils, _ajaxError, _getUrl, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldRender}}
    {{#if @channel.archive.failed}}
      <div
        class={{concat-class
          "alert alert-warn chat-channel-retry-archive"
          @channel.status
        }}
      >
        <div class="chat-channel-archive-failed-message">
          {{this.channelArchiveFailedMessage}}
        </div>
  
        <div class="chat-channel-archive-failed-retry">
          <DButton
            @action={{this.retryArchive}}
            @label="chat.channel_archive.retry"
          />
        </div>
      </div>
    {{else if @channel.archive.completed}}
      <div class={{concat-class "chat-channel-archive-status" @channel.status}}>
        {{this.channelArchiveCompletedMessage}}
      </div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "OSF9OQ/P",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[41,[30,1,[\"archive\",\"failed\"]],[[[1,\"    \"],[10,0],[15,0,[28,[37,1],[\"alert alert-warn chat-channel-retry-archive\",[30,1,[\"status\"]]],null]],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-channel-archive-failed-message\"],[12],[1,\"\\n        \"],[1,[30,0,[\"channelArchiveFailedMessage\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"chat-channel-archive-failed-retry\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@action\",\"@label\"],[[30,0,[\"retryArchive\"]],\"chat.channel_archive.retry\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"archive\",\"completed\"]],[[[1,\"    \"],[10,0],[15,0,[28,[37,1],[\"chat-channel-archive-status\",[30,1,[\"status\"]]],null]],[12],[1,\"\\n      \"],[1,[30,0,[\"channelArchiveCompletedMessage\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]]],[]],null]],[\"@channel\"],false,[\"if\",\"concat-class\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-channel-archive-status.hbs",
    "isStrictMode": false
  });
  let ChatChannelArchiveStatus = _exports.default = (_class = class ChatChannelArchiveStatus extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    }
    get shouldRender() {
      return this.currentUser.admin && (0, _utils.isPresent)(this.args.channel.archive);
    }
    get channelArchiveFailedMessage() {
      const archive = this.args.channel.archive;
      const translationKey = !archive.topicId ? "chat.channel_status.archive_failed_no_topic" : "chat.channel_status.archive_failed";
      return (0, _template.htmlSafe)(_discourseI18n.default.t(translationKey, {
        completed: archive.messages,
        total: archive.totalMessages,
        topic_url: this.topicUrl
      }));
    }
    get channelArchiveCompletedMessage() {
      return (0, _template.htmlSafe)(_discourseI18n.default.t("chat.channel_status.archive_completed", {
        topic_url: this.topicUrl
      }));
    }
    retryArchive() {
      return this.chatApi.createChannelArchive(this.args.channel.id).catch(_ajaxError.popupAjaxError);
    }
    get topicUrl() {
      if (!this.args.channel.archive.topicId) {
        return "";
      }
      return (0, _getUrl.default)(`/t/-/${this.args.channel.archive.topicId}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "retryArchive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "retryArchive"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatChannelArchiveStatus);
});