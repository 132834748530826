define("discourse/plugins/chat/discourse/components/chat-message-text", ["exports", "@ember/component", "@glimmer/component", "discourse/plugins/chat/discourse/components/chat-message-collapser", "@ember/template-factory"], function (_exports, _component, _component2, _chatMessageCollapser, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-message-text">
    {{#if this.isCollapsible}}
      <ChatMessageCollapser
        @cooked={{@cooked}}
        @uploads={{@uploads}}
        @onToggleCollapse={{@onToggleCollapse}}
      />
    {{else}}
      {{html-safe @cooked}}
    {{/if}}
  
    {{#if this.isEdited}}
      <span class="chat-message-edited">({{i18n "chat.edited"}})</span>
    {{/if}}
  
    {{yield}}
  </div>
  */
  {
    "id": "CosMY0zi",
    "block": "[[[10,0],[14,0,\"chat-message-text\"],[12],[1,\"\\n\"],[41,[30,0,[\"isCollapsible\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@cooked\",\"@uploads\",\"@onToggleCollapse\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[[30,1]],null]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"isEdited\"]],[[[1,\"    \"],[10,1],[14,0,\"chat-message-edited\"],[12],[1,\"(\"],[1,[28,[35,3],[\"chat.edited\"],null]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"@cooked\",\"@uploads\",\"@onToggleCollapse\",\"&default\"],false,[\"if\",\"chat-message-collapser\",\"html-safe\",\"i18n\",\"yield\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-message-text.hbs",
    "isStrictMode": false
  });
  class ChatMessageText extends _component2.default {
    get isEdited() {
      return this.args.edited ?? false;
    }
    get isCollapsible() {
      return (0, _chatMessageCollapser.isCollapsible)(this.args.cooked, this.args.uploads);
    }
  }
  _exports.default = ChatMessageText;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMessageText);
});