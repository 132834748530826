define("discourse/plugins/chat/discourse/components/chat-message-in-reply-to-indicator", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @message.inReplyTo}}
    <LinkTo
      @route={{this.route}}
      @models={{this.model}}
      class="chat-reply is-direct-reply"
    >
      {{d-icon "share" title="chat.in_reply_to"}}
  
      {{#if @message.inReplyTo.chatWebhookEvent.emoji}}
        <ChatEmojiAvatar @emoji={{@message.inReplyTo.chatWebhookEvent.emoji}} />
      {{else}}
        <ChatUserAvatar @user={{@message.inReplyTo.user}} />
      {{/if}}
  
      <span class="chat-reply__excerpt">
        {{replace-emoji (html-safe @message.inReplyTo.excerpt)}}
      </span>
    </LinkTo>
  {{/if}}
  */
  {
    "id": "VnKb8LJy",
    "block": "[[[41,[30,1,[\"inReplyTo\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"chat-reply is-direct-reply\"]],[[\"@route\",\"@models\"],[[30,0,[\"route\"]],[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"share\"],[[\"title\"],[\"chat.in_reply_to\"]]]],[1,\"\\n\\n\"],[41,[30,1,[\"inReplyTo\",\"chatWebhookEvent\",\"emoji\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@emoji\"],[[30,1,[\"inReplyTo\",\"chatWebhookEvent\",\"emoji\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,[[\"@user\"],[[30,1,[\"inReplyTo\",\"user\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,1],[14,0,\"chat-reply__excerpt\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[[28,[37,6],[[30,1,[\"inReplyTo\",\"excerpt\"]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@message\"],false,[\"if\",\"link-to\",\"d-icon\",\"chat-emoji-avatar\",\"chat-user-avatar\",\"replace-emoji\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-message-in-reply-to-indicator.hbs",
    "isStrictMode": false
  });
  let ChatMessageInReplyToIndicator = _exports.default = (_class = class ChatMessageInReplyToIndicator extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get route() {
      if (this.hasThread) {
        return "chat.channel.thread";
      } else {
        return "chat.channel.near-message";
      }
    }
    get model() {
      if (this.hasThread) {
        return [...this.args.message.channel.routeModels, this.args.message.thread.id];
      } else {
        return [...this.args.message.channel.routeModels, this.args.message.inReplyTo.id];
      }
    }
    get hasThread() {
      return this.args.message?.channel?.threadingEnabled && this.args.message?.thread?.id;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMessageInReplyToIndicator);
});