define("discourse/plugins/chat/discourse/components/chat-drawer/header/toggle-expand-button", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @icon={{if
      this.chatStateManager.isDrawerExpanded
      "angle-double-down"
      "angle-double-up"
    }}
    @action={{@toggleExpand}}
    @title={{if
      this.chatStateManager.isDrawerExpanded
      "chat.collapse"
      "chat.expand"
    }}
    class="btn-flat btn-link chat-drawer-header__expand-btn"
  />
  */
  {
    "id": "g4xvMUNB",
    "block": "[[[8,[39,0],[[24,0,\"btn-flat btn-link chat-drawer-header__expand-btn\"]],[[\"@icon\",\"@action\",\"@title\"],[[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],\"angle-double-down\",\"angle-double-up\"],[30,1],[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],\"chat.collapse\",\"chat.expand\"]]],null]],[\"@toggleExpand\"],false,[\"d-button\",\"if\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-drawer/header/toggle-expand-button.hbs",
    "isStrictMode": false
  });
  let ChatDrawerHeaderToggleExpandButton = _exports.default = (_class = class ChatDrawerHeaderToggleExpandButton extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatStateManager", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatDrawerHeaderToggleExpandButton);
});