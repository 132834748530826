define("discourse/plugins/chat/discourse/components/chat/thread-list/item/unread-indicator", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showUnreadIndicator}}
    <div class="chat-thread-list-item-unread-indicator">
      <div class="chat-thread-list-item-unread-indicator__number">
        {{this.unreadCountLabel}}
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "rDctiubz",
    "block": "[[[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"  \"],[10,0],[14,0,\"chat-thread-list-item-unread-indicator\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-thread-list-item-unread-indicator__number\"],[12],[1,\"\\n      \"],[1,[30,0,[\"unreadCountLabel\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/thread-list/item/unread-indicator.hbs",
    "isStrictMode": false
  });
  class ChatThreadListItemUnreadIndicator extends _component2.default {
    get unreadCount() {
      return this.args.thread.tracking.unreadCount;
    }
    get showUnreadIndicator() {
      return this.unreadCount > 0;
    }
    get unreadCountLabel() {
      return this.unreadCount > 99 ? "99+" : this.unreadCount;
    }
  }
  _exports.default = ChatThreadListItemUnreadIndicator;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatThreadListItemUnreadIndicator);
});