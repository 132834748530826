define("discourse/plugins/chat/discourse/components/styleguide/organisms/chat", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Styleguide::ChatMessage />
  <Styleguide::ChatComposer />
  <Styleguide::ChatThreadListItem />
  <Styleguide::ChatComposerMessageDetails />
  <Styleguide::ChatHeaderIcon />
  
  <h2>Modals</h2>
  
  <Styleguide::ChatModalArchiveChannel />
  <Styleguide::ChatModalMoveMessageToChannel />
  <Styleguide::ChatModalDeleteChannel />
  <Styleguide::ChatModalEditChannelDescription />
  <Styleguide::ChatModalEditChannelName />
  <Styleguide::ChatModalThreadSettings />
  <Styleguide::ChatModalCreateChannel />
  <Styleguide::ChatModalToggleChannelStatus />
  <Styleguide::ChatModalNewMessage />
  <Styleguide::ChatModalChannelSummary />
  */
  {
    "id": "P8l1UR5w",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[8,[39,2],null,null,null],[1,\"\\n\"],[8,[39,3],null,null,null],[1,\"\\n\"],[8,[39,4],null,null,null],[1,\"\\n\\n\"],[10,\"h2\"],[12],[1,\"Modals\"],[13],[1,\"\\n\\n\"],[8,[39,5],null,null,null],[1,\"\\n\"],[8,[39,6],null,null,null],[1,\"\\n\"],[8,[39,7],null,null,null],[1,\"\\n\"],[8,[39,8],null,null,null],[1,\"\\n\"],[8,[39,9],null,null,null],[1,\"\\n\"],[8,[39,10],null,null,null],[1,\"\\n\"],[8,[39,11],null,null,null],[1,\"\\n\"],[8,[39,12],null,null,null],[1,\"\\n\"],[8,[39,13],null,null,null],[1,\"\\n\"],[8,[39,14],null,null,null]],[],false,[\"styleguide/chat-message\",\"styleguide/chat-composer\",\"styleguide/chat-thread-list-item\",\"styleguide/chat-composer-message-details\",\"styleguide/chat-header-icon\",\"styleguide/chat-modal-archive-channel\",\"styleguide/chat-modal-move-message-to-channel\",\"styleguide/chat-modal-delete-channel\",\"styleguide/chat-modal-edit-channel-description\",\"styleguide/chat-modal-edit-channel-name\",\"styleguide/chat-modal-thread-settings\",\"styleguide/chat-modal-create-channel\",\"styleguide/chat-modal-toggle-channel-status\",\"styleguide/chat-modal-new-message\",\"styleguide/chat-modal-channel-summary\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/organisms/chat.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});