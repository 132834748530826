define("discourse/plugins/chat/discourse/components/chat/scroll-to-bottom-arrow", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-scroll-to-bottom">
    <DButton
      class={{concat-class
        "btn-flat"
        "chat-scroll-to-bottom__button"
        (if @isVisible "visible")
      }}
      @action={{@onScrollToBottom}}
    >
      <span class="chat-scroll-to-bottom__arrow">
        {{d-icon "arrow-down"}}
      </span>
    </DButton>
  </div>
  */
  {
    "id": "FdcovQgc",
    "block": "[[[10,0],[14,0,\"chat-scroll-to-bottom\"],[12],[1,\"\\n  \"],[8,[39,0],[[16,0,[28,[37,1],[\"btn-flat\",\"chat-scroll-to-bottom__button\",[52,[30,1],\"visible\"]],null]]],[[\"@action\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"chat-scroll-to-bottom__arrow\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"arrow-down\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@isVisible\",\"@onScrollToBottom\"],false,[\"d-button\",\"concat-class\",\"if\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/scroll-to-bottom-arrow.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});