define("discourse/plugins/chat/discourse/components/chat-side-panel-resizer", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-side-panel-resizer"></div>
  */
  {
    "id": "9PfqvIVl",
    "block": "[[[10,0],[14,0,\"chat-side-panel-resizer\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-side-panel-resizer.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});