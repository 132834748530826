define("discourse/plugins/chat/discourse/components/chat-composer-message-details", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="chat-composer-message-details"
    data-id={{@message.id}}
    data-action={{if @message.editing "edit" "reply"}}
  >
    <div class="chat-reply">
      {{d-icon (if @message.editing "pencil-alt" "reply")}}
      <ChatUserAvatar @user={{@message.user}} />
      <span class="chat-reply__username">{{@message.user.username}}</span>
      <span class="chat-reply__excerpt">
        {{replace-emoji (html-safe @message.excerpt)}}
      </span>
    </div>
  
    <DButton
      @action={{@cancelAction}}
      @icon="times-circle"
      @title="cancel"
      class="btn-flat cancel-message-action"
    />
  </div>
  */
  {
    "id": "IEh9HUqD",
    "block": "[[[10,0],[14,0,\"chat-composer-message-details\"],[15,\"data-id\",[30,1,[\"id\"]]],[15,\"data-action\",[52,[30,1,[\"editing\"]],\"edit\",\"reply\"]],[12],[1,\"\\n  \"],[10,0],[14,0,\"chat-reply\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[52,[30,1,[\"editing\"]],\"pencil-alt\",\"reply\"]],null]],[1,\"\\n    \"],[8,[39,2],null,[[\"@user\"],[[30,1,[\"user\"]]]],null],[1,\"\\n    \"],[10,1],[14,0,\"chat-reply__username\"],[12],[1,[30,1,[\"user\",\"username\"]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"chat-reply__excerpt\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[[28,[37,4],[[30,1,[\"excerpt\"]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,5],[[24,0,\"btn-flat cancel-message-action\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,2],\"times-circle\",\"cancel\"]],null],[1,\"\\n\"],[13]],[\"@message\",\"@cancelAction\"],false,[\"if\",\"d-icon\",\"chat-user-avatar\",\"replace-emoji\",\"html-safe\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-composer-message-details.hbs",
    "isStrictMode": false
  });
  class ChatComposerMessageDetails extends _component2.default {}
  _exports.default = ChatComposerMessageDetails;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatComposerMessageDetails);
});