define("discourse/plugins/chat/discourse/components/chat-message-thread-indicator", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{concat-class
      "chat-message-thread-indicator"
      (if this.isActive "-active")
    }}
    {{did-insert this.setup}}
    {{will-destroy this.teardown}}
    role="button"
    title={{i18n "chat.threads.open"}}
  >
  
    <div class="chat-message-thread-indicator__last-reply-avatar">
      <ChatUserAvatar
        @user={{@message.thread.preview.lastReplyUser}}
        @avatarSize="small"
      />
    </div>
  
    <div class="chat-message-thread-indicator__last-reply-info">
      <span class="chat-message-thread-indicator__last-reply-username">
        {{@message.thread.preview.lastReplyUser.username}}
      </span>
      <span class="chat-message-thread-indicator__last-reply-timestamp">
        {{format-date @message.thread.preview.lastReplyCreatedAt leaveAgo="true"}}
      </span>
    </div>
    <div class="chat-message-thread-indicator__replies-count">
      {{i18n "chat.thread.replies" count=@message.thread.preview.replyCount}}
    </div>
    <ChatThreadParticipants @thread={{@message.thread}} />
    <div class="chat-message-thread-indicator__last-reply-excerpt">
      {{replace-emoji (html-safe @message.thread.preview.lastReplyExcerpt)}}
    </div>
  </div>
  */
  {
    "id": "UrMTjVxW",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"chat-message-thread-indicator\",[52,[30,0,[\"isActive\"]],\"-active\"]],null]],[24,\"role\",\"button\"],[16,\"title\",[28,[37,2],[\"chat.threads.open\"],null]],[4,[38,3],[[30,0,[\"setup\"]]],null],[4,[38,4],[[30,0,[\"teardown\"]]],null],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-avatar\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@user\",\"@avatarSize\"],[[30,1,[\"thread\",\"preview\",\"lastReplyUser\"]],\"small\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-info\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"chat-message-thread-indicator__last-reply-username\"],[12],[1,\"\\n      \"],[1,[30,1,[\"thread\",\"preview\",\"lastReplyUser\",\"username\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"chat-message-thread-indicator__last-reply-timestamp\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[[30,1,[\"thread\",\"preview\",\"lastReplyCreatedAt\"]]],[[\"leaveAgo\"],[\"true\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"chat-message-thread-indicator__replies-count\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"chat.thread.replies\"],[[\"count\"],[[30,1,[\"thread\",\"preview\",\"replyCount\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,7],null,[[\"@thread\"],[[30,1,[\"thread\"]]]],null],[1,\"\\n  \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-excerpt\"],[12],[1,\"\\n    \"],[1,[28,[35,8],[[28,[37,9],[[30,1,[\"thread\",\"preview\",\"lastReplyExcerpt\"]]],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@message\"],false,[\"concat-class\",\"if\",\"i18n\",\"did-insert\",\"will-destroy\",\"chat-user-avatar\",\"format-date\",\"chat-thread-participants\",\"replace-emoji\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-message-thread-indicator.hbs",
    "isStrictMode": false
  });
  let ChatMessageThreadIndicator = _exports.default = (_class = class ChatMessageThreadIndicator extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "capabilities", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "site", _descriptor5, this);
      _initializerDefineProperty(this, "isActive", _descriptor6, this);
    }
    setup(element) {
      this.element = element;
      if (this.capabilities.touch) {
        this.element.addEventListener("touchstart", this.onTouchStart, {
          passive: true
        });
        this.element.addEventListener("touchmove", this.cancelTouch, {
          passive: true
        });
        this.element.addEventListener("touchend", this.onTouchEnd);
        this.element.addEventListener("touchCancel", this.cancelTouch);
      }
      this.element.addEventListener("click", this.openThread, {
        passive: true
      });
    }
    teardown() {
      if (this.capabilities.touch) {
        this.element.removeEventListener("touchstart", this.onTouchStart, {
          passive: true
        });
        this.element.removeEventListener("touchmove", this.cancelTouch, {
          passive: true
        });
        this.element.removeEventListener("touchend", this.onTouchEnd);
        this.element.removeEventListener("touchCancel", this.cancelTouch);
      }
      this.element.removeEventListener("click", this.openThread, {
        passive: true
      });
    }
    onTouchStart(event) {
      this.isActive = true;
      event.stopPropagation();
      this.touching = true;
    }
    onTouchEnd() {
      this.isActive = false;
      if (this.touching) {
        this.openThread();
      }
    }
    cancelTouch() {
      this.isActive = false;
      this.touching = false;
    }
    openThread() {
      this.chat.activeMessage = null;
      this.router.transitionTo("chat.channel.thread", ...this.args.message.thread.routeModels);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "teardown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTouchStart", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTouchEnd", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onTouchEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelTouch", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "cancelTouch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openThread", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "openThread"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatMessageThreadIndicator);
});