define("discourse/plugins/chat/discourse/components/chat-composer-dropdown", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @buttons.length}}
    <DMenu
      class={{concat-class
        "chat-composer-dropdown__trigger-btn"
        "btn-flat"
        (if @hasActivePanel "has-active-panel")
      }}
      @title={{i18n "chat.composer.toggle_toolbar"}}
      @icon="plus"
      @disabled={{@isDisabled}}
      @arrow={{true}}
      @placements={{array "top" "bottom"}}
      ...attributes
      as |menu|
    >
      <ul class="chat-composer-dropdown__list">
        {{#each @buttons as |button|}}
          <li class={{concat-class "chat-composer-dropdown__item" button.id}}>
            <DButton
              @icon={{button.icon}}
              @action={{fn this.onButtonClick button menu.close}}
              @label={{button.label}}
              class={{concat-class
                "chat-composer-dropdown__action-btn"
                button.id
              }}
            />
          </li>
        {{/each}}
      </ul>
    </DMenu>
  {{/if}}
  */
  {
    "id": "kgRiw3WL",
    "block": "[[[41,[30,1,[\"length\"]],[[[1,\"  \"],[8,[39,1],[[16,0,[28,[37,2],[\"chat-composer-dropdown__trigger-btn\",\"btn-flat\",[52,[30,2],\"has-active-panel\"]],null]],[17,3]],[[\"@title\",\"@icon\",\"@disabled\",\"@arrow\",\"@placements\"],[[28,[37,3],[\"chat.composer.toggle_toolbar\"],null],\"plus\",[30,4],true,[28,[37,4],[\"top\",\"bottom\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"chat-composer-dropdown__list\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,1]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[15,0,[28,[37,2],[\"chat-composer-dropdown__item\",[30,6,[\"id\"]]],null]],[12],[1,\"\\n          \"],[8,[39,7],[[16,0,[28,[37,2],[\"chat-composer-dropdown__action-btn\",[30,6,[\"id\"]]],null]]],[[\"@icon\",\"@action\",\"@label\"],[[30,6,[\"icon\"]],[28,[37,8],[[30,0,[\"onButtonClick\"]],[30,6],[30,5,[\"close\"]]],null],[30,6,[\"label\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[6]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[5]]]]],[1,\"\\n\"]],[]],null]],[\"@buttons\",\"@hasActivePanel\",\"&attrs\",\"@isDisabled\",\"menu\",\"button\"],false,[\"if\",\"d-menu\",\"concat-class\",\"i18n\",\"array\",\"each\",\"-track-array\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-composer-dropdown.hbs",
    "isStrictMode": false
  });
  let ChatComposerDropdown = _exports.default = (_class = class ChatComposerDropdown extends _component2.default {
    onButtonClick(button, closeFn) {
      closeFn();
      button.action();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onButtonClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onButtonClick"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatComposerDropdown);
});