define("discourse/plugins/chat/discourse/services/chat-pane-base-subscriptions-manager", ["exports", "@ember/service", "discourse-common/lib/object", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/models/chat-message"], function (_exports, _service, _object, _decorators, _chatMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.handleStagedMessage = handleStagedMessage;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function handleStagedMessage(channel, messagesManager, data) {
    const stagedMessage = messagesManager.findStagedMessage(data.staged_id);
    if (!stagedMessage) {
      return;
    }
    stagedMessage.error = null;
    stagedMessage.id = data.chat_message.id;
    stagedMessage.staged = false;
    stagedMessage.excerpt = data.chat_message.excerpt;
    stagedMessage.channel = channel;
    stagedMessage.createdAt = new Date(data.chat_message.created_at);
    stagedMessage.cooked = data.chat_message.cooked;
    return stagedMessage;
  }

  /**
   * Handles subscriptions for MessageBus messages sent from Chat::Publisher
   * to the channel and thread panes. There are individual services for
   * each (ChatChannelPaneSubscriptionsManager and ChatThreadPaneSubscriptionsManager)
   * that implement their own logic where necessary. Functions which will
   * always be different between the two raise a "not implemented" error in
   * the base class, and the child class must define the associated function,
   * even if it is a noop in that context.
   *
   * For example, in the thread context there is no need to handle the thread
   * creation event, because the panel will not be open in that case.
   */
  let ChatPaneBaseSubscriptionsManager = _exports.default = (_class = class ChatPaneBaseSubscriptionsManager extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _defineProperty(this, "messageBusChannel", null);
      _defineProperty(this, "messageBusLastId", null);
    }
    get messagesManager() {
      return this.model.messagesManager;
    }
    beforeSubscribe() {}
    afterMessage() {}
    subscribe(model) {
      this.unsubscribe();
      this.beforeSubscribe(model);
      this.model = model;
      if (!this.messageBusChannel) {
        return;
      }
      this.messageBus.subscribe(this.messageBusChannel, this.onMessage, this.messageBusLastId);
    }
    unsubscribe() {
      if (!this.model) {
        return;
      }
      this.messageBus.unsubscribe(this.messageBusChannel, this.onMessage);
      this.model = null;
    }
    handleStagedMessageInternal(channel, data) {
      return handleStagedMessage(channel, this.messagesManager, data);
    }
    onMessage(busData) {
      switch (busData.type) {
        case "sent":
          this.handleSentMessage(busData);
          break;
        case "reaction":
          this.handleReactionMessage(busData);
          break;
        case "processed":
          this.handleProcessedMessage(busData);
          break;
        case "edit":
          this.handleEditMessage(busData);
          break;
        case "refresh":
          this.handleRefreshMessage(busData);
          break;
        case "delete":
          this.handleDeleteMessage(busData);
          break;
        case "bulk_delete":
          this.handleBulkDeleteMessage(busData);
          break;
        case "restore":
          this.handleRestoreMessage(busData);
          break;
        case "self_flagged":
          this.handleSelfFlaggedMessage(busData);
          break;
        case "flag":
          this.handleFlaggedMessage(busData);
          break;
        case "thread_created":
          this.handleNewThreadCreated(busData);
          break;
        case "update_thread_original_message":
          this.handleThreadOriginalMessageUpdate(busData);
          break;
        case "notice":
          this.handleNotice(busData);
          break;
      }
      this.afterMessage(this.model, ...arguments);
    }
    handleSentMessage() {
      throw "not implemented";
    }
    handleProcessedMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message.id);
      if (message) {
        message.cooked = data.chat_message.cooked;
      }
    }
    handleReactionMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message_id);
      if (message) {
        message.react(data.emoji, data.action, data.user, this.currentUser.id);
      }
    }
    handleEditMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message.id);
      if (message) {
        message.message = data.chat_message.message;
        message.cooked = data.chat_message.cooked;
        message.excerpt = data.chat_message.excerpt;
        message.uploads = (0, _object.cloneJSON)(data.chat_message.uploads || []);
        message.edited = data.chat_message.edited;
      }
    }
    handleRefreshMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message.id);
      if (message) {
        message.incrementVersion();
      }
    }
    handleBulkDeleteMessage(data) {
      data.deleted_ids.forEach(deletedId => {
        this.handleDeleteMessage({
          deleted_id: deletedId,
          deleted_at: data.deleted_at
        });
      });
    }
    handleDeleteMessage(data) {
      const deletedId = data.deleted_id;
      const targetMsg = this.messagesManager.findMessage(deletedId);
      if (!targetMsg) {
        return;
      }
      if (this.currentUser.staff || this.currentUser.id === targetMsg.user.id) {
        targetMsg.deletedAt = data.deleted_at;
        targetMsg.deletedById = data.deleted_by_id;
        targetMsg.expanded = false;
      } else {
        this.messagesManager.removeMessage(targetMsg);
      }
      this._afterDeleteMessage(targetMsg, data);
    }
    handleRestoreMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message.id);
      if (message) {
        message.deletedAt = null;
      } else {
        const newMessage = _chatMessage.default.create(this.model, data.chat_message);
        newMessage.manager = this.messagesManager;
        this.messagesManager.addMessages([newMessage]);
      }
    }
    handleSelfFlaggedMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message_id);
      if (message) {
        message.userFlagStatus = data.user_flag_status;
      }
    }
    handleFlaggedMessage(data) {
      const message = this.messagesManager.findMessage(data.chat_message_id);
      if (message) {
        message.reviewableId = data.reviewable_id;
      }
    }
    handleNewThreadCreated(data) {
      this.model.threadsManager.find(this.model.id, data.thread_id, {
        fetchIfNotFound: true
      }).then(thread => {
        const channelOriginalMessage = this.model.messagesManager.findMessage(thread.originalMessage.id);
        if (channelOriginalMessage) {
          channelOriginalMessage.thread = thread;
        }
      });
    }
    handleThreadOriginalMessageUpdate() {
      throw "not implemented";
    }
    handleNotice() {
      throw "not implemented";
    }
    _afterDeleteMessage() {
      throw "not implemented";
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onMessage"), _class.prototype)), _class);
});