define("discourse/plugins/chat/discourse/components/chat/thread/threads-list-button", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    @route="chat.channel.threads"
    @models={{@channel.routeModels}}
    title={{i18n "chat.threads.list"}}
    class={{concat-class
      "chat-threads-list-button btn btn-flat"
      (if @channel.threadsManager.unreadThreadCount "has-unreads")
    }}
  >
    {{d-icon "discourse-threads"}}
  
    <Chat::Thread::HeaderUnreadIndicator @channel={{@channel}} />
  </LinkTo>
  */
  {
    "id": "S775/LGw",
    "block": "[[[8,[39,0],[[16,\"title\",[28,[37,1],[\"chat.threads.list\"],null]],[16,0,[28,[37,2],[\"chat-threads-list-button btn btn-flat\",[52,[30,1,[\"threadsManager\",\"unreadThreadCount\"]],\"has-unreads\"]],null]]],[[\"@route\",\"@models\"],[\"chat.channel.threads\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,4],[\"discourse-threads\"],null]],[1,\"\\n\\n  \"],[8,[39,5],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\"]],[]]]]]],[\"@channel\"],false,[\"link-to\",\"i18n\",\"concat-class\",\"if\",\"d-icon\",\"chat/thread/header-unread-indicator\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/thread/threads-list-button.hbs",
    "isStrictMode": false
  });
  class ChatThreadsListButton extends _component2.default {}
  _exports.default = ChatThreadsListButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatThreadsListButton);
});