define("discourse/plugins/chat/discourse/components/chat/message-creator/user-selection", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatUserAvatar @user={{@selection.model}} @showPresence={{false}} />
  
  <span class="chat-message-creator__selection-item__username">
    {{@selection.model.username}}
  </span>
  */
  {
    "id": "RENqX/aa",
    "block": "[[[8,[39,0],null,[[\"@user\",\"@showPresence\"],[[30,1,[\"model\"]],false]],null],[1,\"\\n\\n\"],[10,1],[14,0,\"chat-message-creator__selection-item__username\"],[12],[1,\"\\n  \"],[1,[30,1,[\"model\",\"username\"]]],[1,\"\\n\"],[13]],[\"@selection\"],false,[\"chat-user-avatar\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/message-creator/user-selection.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});