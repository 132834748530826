define("discourse/plugins/chat/discourse/components/chat-drawer", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "@ember/template", "discourse/lib/url", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _runloop, _service, _template, _url, _utilities, _getUrl, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.chatStateManager.isDrawerActive}}
    <div
      data-chat-channel-id={{this.chat.activeChannel.id}}
      data-chat-thread-id={{this.chat.activeChannel.activeThread.id}}
      class={{concat-class
        "chat-drawer"
        (if this.chatStateManager.isDrawerExpanded "is-expanded")
      }}
      {{chat/resizable-node ".chat-drawer-resizer" this.didResize}}
      style={{this.drawerStyle}}
    >
      <div class="chat-drawer-container">
        <div class="chat-drawer-resizer"></div>
        <this.chatDrawerRouter.component
          @params={{this.chatDrawerRouter.params}}
          @openURL={{this.openURL}}
          @openInFullPage={{this.openInFullPage}}
          @toggleExpand={{this.toggleExpand}}
          @close={{this.close}}
          @drawerActions={{this.drawerActions}}
        />
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "Km2Q7jFJ",
    "block": "[[[41,[30,0,[\"chatStateManager\",\"isDrawerActive\"]],[[[1,\"  \"],[11,0],[16,\"data-chat-channel-id\",[30,0,[\"chat\",\"activeChannel\",\"id\"]]],[16,\"data-chat-thread-id\",[30,0,[\"chat\",\"activeChannel\",\"activeThread\",\"id\"]]],[16,0,[28,[37,1],[\"chat-drawer\",[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],\"is-expanded\"]],null]],[16,5,[30,0,[\"drawerStyle\"]]],[4,[38,2],[\".chat-drawer-resizer\",[30,0,[\"didResize\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"chat-drawer-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-drawer-resizer\"],[12],[13],[1,\"\\n      \"],[8,[30,0,[\"chatDrawerRouter\",\"component\"]],null,[[\"@params\",\"@openURL\",\"@openInFullPage\",\"@toggleExpand\",\"@close\",\"@drawerActions\"],[[30,0,[\"chatDrawerRouter\",\"params\"]],[30,0,[\"openURL\"]],[30,0,[\"openInFullPage\"]],[30,0,[\"toggleExpand\"]],[30,0,[\"close\"]],[30,0,[\"drawerActions\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"concat-class\",\"chat/resizable-node\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-drawer.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_dec = (0, _decorators.observes)("chatStateManager.isDrawerActive"), (_obj = {
    tagName: "",
    chat: (0, _service.inject)(),
    router: (0, _service.inject)(),
    chatDrawerSize: (0, _service.inject)(),
    chatChannelsManager: (0, _service.inject)(),
    chatStateManager: (0, _service.inject)(),
    chatDrawerRouter: (0, _service.inject)(),
    loading: false,
    sizeTimer: null,
    rafTimer: null,
    hasUnreadMessages: false,
    drawerStyle: null,
    didInsertElement() {
      this._super(...arguments);
      if (!this.chat.userCanChat) {
        return;
      }
      this._checkSize();
      this.appEvents.on("chat:open-url", this, "openURL");
      this.appEvents.on("chat:toggle-close", this, "close");
      this.appEvents.on("composer:closed", this, "_checkSize");
      this.appEvents.on("composer:opened", this, "_checkSize");
      this.appEvents.on("composer:resized", this, "_checkSize");
      this.appEvents.on("composer:div-resizing", this, "_dynamicCheckSize");
      window.addEventListener("resize", this._checkSize);
      this.appEvents.on("composer:resize-started", this, "_startDynamicCheckSize");
      this.appEvents.on("composer:resize-ended", this, "_clearDynamicCheckSize");
      this.computeDrawerStyle();
    },
    willDestroyElement() {
      this._super(...arguments);
      if (!this.chat.userCanChat) {
        return;
      }
      window.removeEventListener("resize", this._checkSize);
      if (this.appEvents) {
        this.appEvents.off("chat:open-url", this, "openURL");
        this.appEvents.off("chat:toggle-close", this, "close");
        this.appEvents.off("composer:closed", this, "_checkSize");
        this.appEvents.off("composer:opened", this, "_checkSize");
        this.appEvents.off("composer:resized", this, "_checkSize");
        this.appEvents.off("composer:div-resizing", this, "_dynamicCheckSize");
        this.appEvents.off("composer:resize-started", this, "_startDynamicCheckSize");
        this.appEvents.off("composer:resize-ended", this, "_clearDynamicCheckSize");
      }
      if (this.sizeTimer) {
        (0, _runloop.cancel)(this.sizeTimer);
        this.sizeTimer = null;
      }
      if (this.rafTimer) {
        window.cancelAnimationFrame(this.rafTimer);
      }
    },
    _fireHiddenAppEvents() {
      this.appEvents.trigger("chat:rerender-header");
    },
    computeDrawerStyle() {
      const {
        width,
        height
      } = this.chatDrawerSize.size;
      let style = `width: ${(0, _utilities.escapeExpression)((width || "0").toString())}px;`;
      style += `height: ${(0, _utilities.escapeExpression)((height || "0").toString())}px;`;
      this.set("drawerStyle", (0, _template.htmlSafe)(style));
    },
    get drawerActions() {
      return {
        openInFullPage: this.openInFullPage,
        close: this.close,
        toggleExpand: this.toggleExpand
      };
    },
    _dynamicCheckSize() {
      if (!this.chatStateManager.isDrawerActive) {
        return;
      }
      if (this.rafTimer) {
        return;
      }
      this.rafTimer = window.requestAnimationFrame(() => {
        this.rafTimer = null;
        this._performCheckSize();
      });
    },
    _startDynamicCheckSize() {
      if (!this.chatStateManager.isDrawerActive) {
        return;
      }
      document.querySelector(".chat-drawer-outlet-container").classList.add("clear-transitions");
    },
    _clearDynamicCheckSize() {
      if (!this.chatStateManager.isDrawerActive) {
        return;
      }
      document.querySelector(".chat-drawer-outlet-container").classList.remove("clear-transitions");
      this._checkSize();
    },
    _checkSize() {
      this.sizeTimer = (0, _runloop.throttle)(this, this._performCheckSize, 150);
    },
    _performCheckSize() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      const drawerContainer = document.querySelector(".chat-drawer-outlet-container");
      if (!drawerContainer) {
        return;
      }
      const composer = document.getElementById("reply-control");
      const composerIsClosed = composer.classList.contains("closed");
      const minRightMargin = 15;
      drawerContainer.style.setProperty("--composer-right", (composerIsClosed ? minRightMargin : Math.max(minRightMargin, composer.offsetLeft)) + "px");
    },
    openURL() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.chat.activeChannel = null;
      this.chatDrawerRouter.stateFor(this._routeFromURL(url));
      this.chatStateManager.didOpenDrawer(url);
    },
    _routeFromURL(url) {
      let route = this.router.recognize((0, _getUrl.default)(url || "/"));

      // ember might recognize the index subroute
      if (route.localName === "index") {
        route = route.parent;
      }
      return route;
    },
    openInFullPage() {
      this.chatStateManager.storeAppURL();
      this.chatStateManager.prefersFullPage();
      this.chat.activeChannel = null;
      return _url.default.routeTo(this.chatStateManager.lastKnownChatURL);
    },
    toggleExpand() {
      this.computeDrawerStyle();
      this.chatStateManager.didToggleDrawer();
      this.appEvents.trigger("chat:toggle-expand", this.chatStateManager.isDrawerExpanded);
    },
    close() {
      this.computeDrawerStyle();
      this.chatStateManager.didCloseDrawer();
      this.chat.activeChannel = null;
    },
    didResize(element, _ref) {
      let {
        width,
        height
      } = _ref;
      this.chatDrawerSize.size = {
        width,
        height
      };
    }
  }, (_applyDecoratedDescriptor(_obj, "_fireHiddenAppEvents", [_dec], Object.getOwnPropertyDescriptor(_obj, "_fireHiddenAppEvents"), _obj), _applyDecoratedDescriptor(_obj, "_dynamicCheckSize", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_dynamicCheckSize"), _obj), _applyDecoratedDescriptor(_obj, "_checkSize", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_checkSize"), _obj), _applyDecoratedDescriptor(_obj, "openURL", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openURL"), _obj), _applyDecoratedDescriptor(_obj, "openInFullPage", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openInFullPage"), _obj), _applyDecoratedDescriptor(_obj, "toggleExpand", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleExpand"), _obj), _applyDecoratedDescriptor(_obj, "close", [_object.action], Object.getOwnPropertyDescriptor(_obj, "close"), _obj), _applyDecoratedDescriptor(_obj, "didResize", [_object.action], Object.getOwnPropertyDescriptor(_obj, "didResize"), _obj)), _obj))));
});