define("discourse/plugins/chat/discourse/components/chat-drawer/header/title", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="chat-drawer-header__title">
    <div class="chat-drawer-header__top-line">
      {{i18n @title}}
    </div>
  </span>
  */
  {
    "id": "h7tnAKNT",
    "block": "[[[10,1],[14,0,\"chat-drawer-header__title\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"chat-drawer-header__top-line\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,1]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@title\"],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-drawer/header/title.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});